var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content palm-bg"},[_c('div',{staticClass:"row",staticStyle:{"background":"rgba(45, 91, 88, 0.4)"}},[_c('section',{staticClass:"text-center col-lg-8 col-sm-12"},[_c('div',{staticClass:"centered text-center",staticStyle:{"width":"100%"},style:({
          background: ("linear-gradient(" + (this.secondaryColor) + ", black)"),
        })},[_c('HeadImage',{staticClass:"centered",attrs:{"image":_vm.mainImage,"align":"text-center","title":"TULUM PARTY GUIDE","caption":"Tulum Decoded"}}),_c('p',[_vm._v(" The Tulum Party Guide is where you will find everything related to the House and Techno Musical Nightlife and Culture of Tulum. A gameplay for every player in the Scene: from how to reserve a table and the detailed menus of prominent restaurants, to all you need to know about the ins and outs of Tulum, its "),_c('router-link',{attrs:{"to":{ path: '/tulum-clubs' }}},[_vm._v("Clubs")]),_vm._v(" , House Parties, "),_c('router-link',{attrs:{"to":{ path: '/brands' }}},[_vm._v("Production brands")]),_vm._v(" , and the famous "),_c('router-link',{attrs:{"to":{ path: '/tulum-new-year-festival-2024' }}},[_vm._v("high-season NYE Festival in Tulum.")])],1),_c('br')],1),_c('hr'),_vm._m(0),_c('div',{staticClass:"row",attrs:{"id":"articles"}},_vm._l((_vm.articles),function(article,idx){return _c('div',{key:idx,staticClass:"col-sm-12 card card-animation-on-hover-no-bg"},[_c('div',{staticClass:"centered",staticStyle:{"width":"100%"},style:({
              background: ("linear-gradient(" + _vm.secondaryColor + ", black)"),
            })},[_c('router-link',{attrs:{"to":{ path: '/article/' + article.slug }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  article.metadata.cover.imgix_url +
                  '?w=315&h=200&fit=fillmax&fill=blur&auto=format'
                ),expression:"\n                  article.metadata.cover.imgix_url +\n                  '?w=315&h=200&fit=fillmax&fill=blur&auto=format'\n                "}],staticClass:"d-inline-xs d-sm-none",staticStyle:{"margin-top":"-10px","opacity":"0.8","z-index":"12","box-shadow":"0 10px 15px 0 rgba(0 0 0, 0.6)","border-radius":"0"},attrs:{"alt":" TULUM PARTY BLOG","width":"315px","height":"200px"}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  article.metadata.cover.imgix_url +
                  '?h=300&w=615&fit=fillmax&fill=blur&auto=format'
                ),expression:"\n                  article.metadata.cover.imgix_url +\n                  '?h=300&w=615&fit=fillmax&fill=blur&auto=format'\n                "}],staticClass:"d-none d-sm-inline",staticStyle:{"margin-top":"-10px","opacity":"0.8","z-index":"12","box-shadow":"0px 10px 15px 0px rgba(0 0 0, 0.6)","border-radius":"0"},attrs:{"alt":" TULUM PARTY BLOG","width":"615px","height":"300px"}}),_c('div',{staticClass:"text-left"},[_c('h3',{staticClass:"section-header text-left",staticStyle:{"text-transform":"capitalize","font-size":"1.1rem"}},[_vm._v(" "+_vm._s(article.title)+" ")]),_c('p',{staticStyle:{"padding":"10px","margin-top":"-50px","font-size":"11px","text-transform":"capitalize","font-weight":"lighter"}},[_vm._v(" "+_vm._s(article.metadata.caption)+" ")]),_c('div',{staticClass:"card",staticStyle:{"margin-top":"10px"}},[_c('small',[_c('i',{staticClass:"tim-icons icon-calendar-60",style:({
                        color: _vm.secondaryColor,
                        borderRadius: '100px',
                        padding: '5px',
                      })}),_vm._v(" "+_vm._s(new Date(article.created_at).toDateString()))]),_c('small',[_c('i',{staticClass:"tim-icons icon-pencil",style:({
                        color: _vm.secondaryColor,
                        borderRadius: '100px',
                        padding: '5px',
                      })}),_vm._v(" "+_vm._s(new Date(article.published_at).toDateString())+" ")])]),_c('h5',{staticClass:"section-header"},[_c('i',{staticClass:"tim-icons icon-light-3",staticStyle:{"font-size":"12px"},style:({
                      color: _vm.secondaryColor,
                      border: ("2px solid " + _vm.secondaryColor),
                      borderRadius: '100px',
                      padding: '5px',
                    })}),_vm._v(" SUMMARY ")]),_c('div',{attrs:{"id":"article-summary"},domProps:{"innerHTML":_vm._s(article.metadata.summary)}}),_c('br')])])],1)])}),0),_c('hr'),_c('p',[_vm._v(" Showing "+_vm._s(_vm.val > 1 ? _vm.pageSize + 1 : 1)+" - "+_vm._s(_vm.articles ? _vm.articles.length + (_vm.val > 1 ? _vm.pageSize : 0) : 0)+" of "+_vm._s(_vm.total)+" ARTICLE | "),(!_vm.showingAll)?_c('a',{staticClass:"btn btn-link",staticStyle:{"display":"inline-block"},on:{"click":_vm.showAll}},[_vm._v("Show all ("+_vm._s(_vm.total)+")")]):_vm._e()]),(!_vm.showingAll)?_c('BasePagination',{attrs:{"type":"primary","per-page":_vm.pageSize,"total":_vm.total,"value":_vm.val,"nextPage":_vm.nextPage,"prevPage":_vm.prevPage,"changePage":_vm.changePage}}):_vm._e(),_c('SmartMenu',{attrs:{"links":_vm.links,"logo":this.mainImage,"ctaText":"DROP US A LINE","top-section-name":"TULUM.PARTY"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 text-center"},[_c('p',[_c('strong',[_vm._v("Feeling Lost?")])]),_c('hr'),_c('p',[_vm._v(" We Are Your Single Contact for All Clubs & Events: "),_c('strong',[_vm._v("Simplify your planning!")])]),_c('hr')])])])],1),_c('aside',{staticClass:"card col-4 col-lg-4 d-none d-lg-inline"},[_c('SmartMenu',{attrs:{"links":_vm.links,"logo":_vm.astroImg,"top-section-name":"TALK TO US","cta-text":"DROP US A LINE","primary-color":_vm.primaryColor}},[_c('p',[_vm._v(" All Clubs & Events "),_c('br'),_vm._v("Information & Reservations "),_c('br'),_c('br')])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"text-justify"},[_c('h3',{staticClass:"section-header"},[_vm._v("ENTRIES")])])}]

export { render, staticRenderFns }